<div class="flex items-center justify-between divide-gray-200 px-6 pb-4 pt-3">
  <div>Page {{ currentPage }} of {{ totalPage }}</div>

  <div class="inline-flex gap-3">
    <button
      type="button"
      [disabled]="currentPage === 1"
      (click)="previousPage()"
      [ngClass]="
        currentPage === 1
          ? 'cursor-not-allowed text-gray-200'
          : 'text-mango-gray700 '
      "
      class="rounded-md bg-white px-3.5 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      Previous
    </button>
    <button
      type="button"
      [disabled]="totalPage === currentPage"
      (click)="nextPage()"
      [ngClass]="
        totalPage === currentPage
          ? 'cursor-not-allowed text-gray-200'
          : 'text-gray-900'
      "
      class="rounded-md bg-white px-3.5 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      Next
    </button>
  </div>
</div>
