import {
  Component,
  OnDestroy,
  OnInit,
  inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule, Location } from '@angular/common';

@Component({
  selector: 'app-table-navigator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './table-navigator.component.html',
  styleUrl: './table-navigator.component.scss',
})
export class TableNavigatorComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  router = inject(Router);
  route = inject(ActivatedRoute);
  location = inject(Location);

  currentPage = 1;
  @Input({ required: true, alias: 'total' }) totalCount!: number;
  @Output('onNavigation') navigate: EventEmitter<number> = new EventEmitter(false);

  ngOnInit(): void {
    const sub = this.route.queryParams.subscribe({
      next: (params) => {
        if (params['page']) {
          this.currentPage = Number.parseInt(params['page']) || 1;
          this.navigate.next(this.currentPage);
        }
      },
    });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  updatePageParam(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
    this.currentPage = page;
  }

  nextPage() {
    if (this.currentPage < this.totalPage) {
      this.updatePageParam(this.currentPage + 1);
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.updatePageParam(this.currentPage - 1);
    }
  }

  get totalPage() {
    const remain = this.totalCount % 10;
    const pages = Math.floor(this.totalCount / 10);
    return remain > 0 ? pages + 1 : pages;
  }
}